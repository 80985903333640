import React, {
  FC,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from 'react';
import { ArrayElement } from '@magicbrief/common';
import classNames from 'classnames';
import {
  Button,
  Menu,
  MenuItem,
  MenuTrigger,
  Text,
} from 'react-aria-components';
import { useNavigate, useParams } from 'react-router-dom';
import Facebook from 'src/assets/svgicons/brands/Facebook.svg';
import FolderReport from 'src/assets/svgicons/custom/FolderReport.svg';
import Target05 from 'src/assets/svgicons/duocolor/target-05.svg';
import Trash01 from 'src/assets/svgicons/duocolor/trash-01.svg';
import { FacebookAdAccountSelectModal } from 'src/components/FacebookConnect/FacebookAdAccountSelect';
import { Icon } from 'src/components/Icon';
import { UpgradeModalV2 } from 'src/components/Modals/UpgradeModalV2/UpgradeModalV2';
import Sidebar from 'src/components/Sidebar/Sidebar';
import { SquareLoaders } from 'src/components/SquareLoaders';
import { useI18nContext } from 'src/i18n/i18n-react';
import { cn } from 'src/lib/cn';
import { trpc } from 'src/lib/trpc';
import { showNewMessage } from 'src/utils/intercom';
import { useEntitlementQuotas } from 'src/utils/useEntitlementQuotas';
import { useFacebook } from 'src/utils/useFacebook';
import { Popover } from 'src/components/Popover/AriaPopover';
import { SlackGradient } from '../../../assets/svgicons/brands';
import Copy01 from '../../../assets/svgicons/duocolor/copy-01.svg';
import DotsHorizontal from '../../../assets/svgicons/duocolor/dots-horizontal.svg';
import Edit01 from '../../../assets/svgicons/duocolor/edit-01.svg';
import Settings01 from '../../../assets/svgicons/duocolor/settings-01.svg';
import BarChartSquare02 from '../../../assets/svgicons/line/bar-chart-square-02.svg';
import ChevronSelectorVertical from '../../../assets/svgicons/line/chevron-selector-vertical.svg';
import AlertCircle from '../../../assets/svgicons/duotone/alert-circle.svg';
import { AriaMenu } from '../../../components/AriaMenu/AriaMenu';
import { AriaButton } from '../../../components/Button/Button';
import { GetManyInsightsReportsResponse } from '../../../types/insights';
import useFeatureFlag from '../../../utils/useFeatureFlag';
import { useUserAndOrganisation } from '../../../utils/useUserAndOrganisation';
import { useInsightsFacebookAccount } from '../util/useInsightsQueries';
import DeleteReportModal from './DeleteReportModal';
import DuplicateReportModal from './DuplicateReportModal';
import ReconnectRequiredModal from './ReconnectRequiredModal';
import RenameReportModal from './RenameReportModal';

type ReportModalState = {
  report: ArrayElement<GetManyInsightsReportsResponse> | null;
  modal: 'rename' | 'duplicate' | 'delete';
};

const InsightsSidebar: FC = () => {
  const { accountUuid } = useParams();
  const { authenticate, pendingAuthState, setPendingAuthState } = useFacebook();
  const { LL } = useI18nContext();
  const trpcUtils = trpc.useUtils();
  const user = useUserAndOrganisation();

  const [reportModal, setReportModal] = useState<ReportModalState | null>(null);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [isCurrentAccountDisabled, setIsCurrentAccountDisabled] =
    useState<boolean>(false);
  const [showReconnectRequiredModal, setShowReconnectRequiredModal] =
    useState<boolean>(false);
  const hasReconnectModalDisplay = useRef(false);

  const { facebookAdAccount } = useInsightsFacebookAccount({
    accountUuid,
  });

  const slackEnabled = useFeatureFlag('SLACK_CONNECT_ENABLED');
  const copilotEnabled = useFeatureFlag('INSIGHTS_COPILOT_ENABLED');

  const integrations = trpc.integrations.getIntegrations.useQuery(undefined, {
    enabled: !!user.data,
  });
  const facebookIntegrations =
    integrations.data?.filter(
      (integration) => integration.integrationType === 'facebook'
    ) || [];

  const facebookAdAccounts = trpc.insights.getManyFacebookAdAccounts.useQuery(
    undefined,
    { enabled: !!integrations.data }
  );

  const currentAdAccount = facebookAdAccounts.data?.find(
    (adAccount) => adAccount.uuid === accountUuid
  );

  useEffect(() => {
    const isDisabled =
      facebookAdAccount.data?.InsightsOrganisationAdAccountFacebook?.Integration
        ?.config?.status === 'disabled';
    setIsCurrentAccountDisabled(isDisabled);
  }, [
    facebookAdAccount.data?.InsightsOrganisationAdAccountFacebook?.Integration
      ?.config?.status,
  ]);

  const userUuidWhoConnectedAccount =
    facebookAdAccount.data?.InsightsOrganisationAdAccountFacebook?.Integration
      ?.userUuid;

  const getPermissionsForEntityQuery =
    trpc.permissions.getPermissionsForEntity.useQuery(
      {
        entityType: 'Organisation',
        entityUuid: user.data?.organisation.uuid ?? '',
      },
      {
        enabled: isCurrentAccountDisabled && !!userUuidWhoConnectedAccount,
      }
    );

  const permissionForUserWhoConnectedAccount =
    getPermissionsForEntityQuery.data?.direct?.find(
      (permission) =>
        permission.metadata != null &&
        permission.ownerType === 'User' &&
        permission.ownerUuid === userUuidWhoConnectedAccount
    );
  const userUuid = permissionForUserWhoConnectedAccount?.ownerUuid ?? undefined;
  const userName =
    permissionForUserWhoConnectedAccount?.metadata?.type === 'User'
      ? permissionForUserWhoConnectedAccount?.metadata.name ?? undefined
      : undefined;
  const userEmail =
    permissionForUserWhoConnectedAccount?.metadata?.type === 'User'
      ? permissionForUserWhoConnectedAccount.metadata.email ?? undefined
      : undefined;
  const userProfilePictureUrl =
    permissionForUserWhoConnectedAccount?.metadata?.type === 'User'
      ? permissionForUserWhoConnectedAccount.metadata.profilePicURL ?? undefined
      : undefined;

  useEffect(() => {
    setShowReconnectRequiredModal(false);
    if (
      isCurrentAccountDisabled &&
      hasReconnectModalDisplay.current === false
    ) {
      setShowReconnectRequiredModal(true);
      // we only want to show this once even throughout multiple refreshes
      hasReconnectModalDisplay.current = true;
    }
  }, [isCurrentAccountDisabled]);

  const onReconnectRequiredModalClose = (
    event?: 'contact-support' | 'connect-accounts'
  ) => {
    setShowReconnectRequiredModal(false);
    if (event === 'contact-support') {
      showNewMessage({
        message: `Insights Ad Account Disabled Request\nEmail: <INSERT HERE>\nFeedback: <INSERT HERE>\n`,
      });
    } else if (event === 'connect-accounts') {
      void authenticate();
    }
  };

  return (
    <>
      <Sidebar
        side="left"
        className="flex h-full w-full flex-col justify-between gap-6 overflow-y-auto bg-white p-5"
      >
        <Sidebar.Content className="min-h-0">
          <Sidebar.Section
            title={`${LL.insights.sidebar.facebook.title()} (${
              facebookAdAccounts.data?.length ?? 0
            })`}
          >
            {facebookAdAccounts.isLoading && (
              <SquareLoaders amount={5} className="h-11" />
            )}

            {!facebookAdAccounts.isLoading &&
              facebookAdAccounts.data &&
              facebookAdAccounts.data?.length === 0 && (
                <div className="text-xs text-purple-800/40">
                  {LL.insights.sidebar.facebook.noAccounts()}
                </div>
              )}

            {!facebookAdAccounts.isLoading &&
              facebookAdAccounts.data &&
              facebookAdAccounts.data.length > 0 &&
              currentAdAccount && (
                <>
                  <div className="pb-2">
                    <InsightsSidebarAccountMenu
                      setShowUpgradeModal={setShowUpgradeModal}
                    />
                  </div>

                  <Sidebar.NavLink
                    end
                    to={`/insights/accounts/${currentAdAccount.uuid}`}
                  >
                    <Icon>
                      <BarChartSquare02 />
                    </Icon>
                    {LL.insights.sidebar.facebook.overview()}
                  </Sidebar.NavLink>
                  {copilotEnabled && (
                    <Sidebar.NavLink
                      to={`/insights/analysis/${currentAdAccount.uuid}`}
                    >
                      <Icon>
                        <Target05 />
                      </Icon>
                      {LL.insights.sidebar.facebook.analysis()}
                    </Sidebar.NavLink>
                  )}
                </>
              )}
          </Sidebar.Section>

          {facebookIntegrations.length > 0 && accountUuid && (
            <InsightsSidebarReports setReportModal={setReportModal} />
          )}
          {slackEnabled && currentAdAccount && (
            <Sidebar.Section title="Manage">
              <Sidebar.NavLink
                data-intercom-target="insight_slack"
                to={`/insights/schedules/${currentAdAccount.uuid}`}
              >
                <div className="flex w-full flex-row items-center justify-between gap-2">
                  <span className="flex flex-row items-center gap-2 text-sm font-semibold text-primary">
                    <Icon className="h-5 w-5">
                      <SlackGradient />
                    </Icon>
                    <span>
                      {LL.insights.summarySchedules.sidebar.navLinkPrimaryText()}
                    </span>
                  </span>
                </div>
              </Sidebar.NavLink>

              <Sidebar.NavLink
                to={`/insights/accounts/${currentAdAccount.uuid}/settings`}
              >
                <div className="flex w-full flex-row items-center justify-between gap-2">
                  <span className="flex flex-row items-center gap-2 text-sm font-semibold text-primary">
                    <Icon className="h-5 w-5">
                      <Settings01 />
                    </Icon>
                    <span>Account & Data Settings</span>
                  </span>
                </div>
              </Sidebar.NavLink>
            </Sidebar.Section>
          )}
        </Sidebar.Content>
      </Sidebar>

      {reportModal && reportModal.report && (
        <RenameReportModal
          reportUuid={reportModal.report.uuid}
          reportName={reportModal.report.name}
          show={reportModal.modal === 'rename'}
          onClose={() => setReportModal(null)}
        />
      )}
      {reportModal && reportModal.report && (
        <DuplicateReportModal
          reportUuid={reportModal.report.uuid}
          reportName={reportModal.report.name}
          show={reportModal.modal === 'duplicate'}
          onClose={() => setReportModal(null)}
        />
      )}
      {reportModal && reportModal.report && (
        <DeleteReportModal
          reportUuid={reportModal.report.uuid}
          reportName={reportModal.report.name}
          show={reportModal.modal === 'delete'}
          onClose={() => setReportModal(null)}
        />
      )}
      {showReconnectRequiredModal && (
        <ReconnectRequiredModal
          show={showReconnectRequiredModal}
          onClose={onReconnectRequiredModalClose}
          userUuid={userUuid}
          userName={userName}
          userEmail={userEmail}
          userProfilePictureUrl={userProfilePictureUrl}
        />
      )}
      {user.data && isCurrentAccountDisabled && (
        <FacebookAdAccountSelectModal
          pendingAuthState={
            pendingAuthState
              ? {
                  ...pendingAuthState,
                  currentIntegrations: user.data.facebookIntegrations,
                }
              : null
          }
          onOpenChange={(isOpen) => {
            if (!isOpen) {
              setPendingAuthState(null);
              void trpcUtils.user.getUserAndOrganisation.invalidate();
              setTimeout(() => {
                void facebookAdAccounts.refetch();
              }, 3000);
            }
          }}
        />
      )}
      <UpgradeModalV2
        isOpen={showUpgradeModal}
        close={() => setShowUpgradeModal(false)}
        prompt="to unlock insights"
      />
    </>
  );
};

export default InsightsSidebar;

interface InsightsSidebarReportsProps {
  setReportModal: React.Dispatch<React.SetStateAction<ReportModalState | null>>;
}

const InsightsSidebarReports = ({
  setReportModal,
}: InsightsSidebarReportsProps) => {
  const { accountUuid, reportUuid } = useParams();
  const { LL } = useI18nContext();
  const navigate = useNavigate();
  const user = useUserAndOrganisation();

  const reports = trpc.insightsReports.getManyInsightsReports.useQuery(
    { insightsAdAccountFacebookUuid: accountUuid },
    { enabled: !!user.data && !!accountUuid }
  );

  const hasNoReports =
    !reports.isLoading && (reports.data == null || reports.data?.length === 0);

  if (reports.isLoading) {
    return (
      <InsightsSidebarReportsSection>
        <SquareLoaders amount={5} className="h-11" />
      </InsightsSidebarReportsSection>
    );
  }

  if (hasNoReports) {
    return (
      <InsightsSidebarReportsSection>
        <div className="text-xs text-purple-800/40">No reports</div>
      </InsightsSidebarReportsSection>
    );
  }

  return (
    <InsightsSidebarReportsSection>
      <div className="overflow-y-auto">
        {reports.data?.map((report) => {
          const active = reportUuid === report.uuid;
          return (
            <Sidebar.Button
              className={classNames(
                'group w-full disabled:text-purple-200 disabled:hover:bg-white',
                active && 'bg-purple-100'
              )}
              key={report.uuid}
              onClick={() => {
                navigate(
                  `/insights/reports/${report.insightsAdAccountFacebookUuid}/${report.uuid}`
                );
              }}
            >
              <div className="flex w-full flex-row items-center justify-between gap-2.5">
                <div className="flex h-full w-full flex-row items-center gap-2 overflow-hidden">
                  <Icon className="flex-centered flex h-5 w-5 shrink-0 group-disabled:stroke-purple-200">
                    <FolderReport />
                  </Icon>
                  <span className="truncate">{report.name}</span>
                </div>
                <div className="invisible group-hover:visible group-focus:visible">
                  <AriaMenu>
                    <Button className="group/report-options flex items-center justify-center rounded outline-none">
                      <span className="flex h-5 w-5 items-center justify-center rounded bg-none text-text-secondary group-hover/report-options:bg-purple-200">
                        <Icon className="h-4 w-4">
                          <DotsHorizontal />
                        </Icon>
                      </span>
                    </Button>
                    <AriaMenu.List
                      onAction={(key) => {
                        if (key === 'rename') {
                          setReportModal({ report, modal: 'rename' });
                        } else if (key === 'duplicate') {
                          setReportModal({ report, modal: 'duplicate' });
                        } else if (key === 'delete') {
                          setReportModal({ report, modal: 'delete' });
                        }
                      }}
                    >
                      <AriaMenu.Item
                        id="rename"
                        icon={
                          <Icon>
                            <Edit01 />
                          </Icon>
                        }
                      >
                        {LL.insights.sidebar.reports.menu.rename()}
                      </AriaMenu.Item>
                      <AriaMenu.Item
                        id="duplicate"
                        icon={
                          <Icon>
                            <Copy01 />
                          </Icon>
                        }
                      >
                        {LL.insights.sidebar.reports.menu.duplicate()}
                      </AriaMenu.Item>
                      <AriaMenu.Item
                        id="delete"
                        icon={
                          <Icon>
                            <Trash01 />
                          </Icon>
                        }
                        danger={true}
                      >
                        {LL.insights.sidebar.reports.menu.delete()}
                      </AriaMenu.Item>
                    </AriaMenu.List>
                  </AriaMenu>
                </div>
              </div>
            </Sidebar.Button>
          );
        })}
      </div>
    </InsightsSidebarReportsSection>
  );
};

const InsightsSidebarReportsSection = ({ children }: PropsWithChildren) => {
  const { LL } = useI18nContext();

  return (
    <Sidebar.Section
      title={LL.insights.sidebar.reports.title()}
      className="grow overflow-hidden"
      contentClassName="overflow-y-auto"
    >
      {children}
    </Sidebar.Section>
  );
};

const InsightsSidebarAccountMenu = ({
  setShowUpgradeModal,
}: {
  setShowUpgradeModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { accountUuid } = useParams();
  const { authenticate, pendingAuthState, setPendingAuthState } = useFacebook();
  const { LL } = useI18nContext();
  const { quota } = useEntitlementQuotas('insights_ad_account');
  const navigate = useNavigate();
  const trpcUtils = trpc.useUtils();
  const user = useUserAndOrganisation();

  const integrations = trpc.integrations.getIntegrations.useQuery(undefined, {
    enabled: !!user.data,
  });
  const facebookAdAccounts = trpc.insights.getManyFacebookAdAccounts.useQuery(
    undefined,
    { enabled: !!integrations.data }
  );

  const currentAdAccount = facebookAdAccounts.data?.find(
    (adAccount) => adAccount.uuid === accountUuid
  );

  const isLoading = user.isFetching || facebookAdAccounts.isFetching;
  const hasHitAdAccountLimit =
    quota != null &&
    facebookAdAccounts.data?.length &&
    facebookAdAccounts.data.length >= quota;

  if (!currentAdAccount) return null;

  return (
    <>
      <MenuTrigger>
        <AriaButton
          className="grow-1 flex w-full flex-row justify-between font-semibold"
          variant="secondary"
        >
          <span className="truncate">{currentAdAccount.name}</span>
          <Icon className="h-5 w-5 shrink-0">
            <ChevronSelectorVertical />
          </Icon>
        </AriaButton>

        <Popover>
          <Menu
            className="relative flex max-h-64 w-72 flex-col gap-2 overflow-y-auto p-2 focus:outline-none"
            selectionMode="single"
            selectedKeys={[currentAdAccount.uuid]}
          >
            {facebookAdAccounts.data
              ?.sort((a, b) => a.name.localeCompare(b.name))
              .map((facebookAdAccount, index) => {
                const isLocked = index + 1 > (quota ?? 0);

                return (
                  <AriaMenu.Item
                    key={facebookAdAccount.uuid}
                    id={facebookAdAccount.uuid}
                    showCheckbox={true}
                    className={cn('min-h-10', isLocked ? 'blur-sm' : '')}
                    contentClassName="truncate"
                    onAction={() =>
                      isLocked
                        ? setShowUpgradeModal(true)
                        : navigate(
                            `/insights/accounts/${facebookAdAccount.uuid}`
                          )
                    }
                  >
                    <Text slot="label">{facebookAdAccount.name}</Text>
                  </AriaMenu.Item>
                );
              })}

            {hasHitAdAccountLimit ? (
              <MenuItem
                className="flex items-center gap-2 rounded-md border border-solid border-red-50 bg-red-50 p-3 hover:border-red-500 focus:outline-none"
                onAction={() => setShowUpgradeModal(true)}
              >
                <Icon className="size-5 text-red-800">
                  <AlertCircle />
                </Icon>
                <span className="cursor-default text-xs font-medium text-red-800">
                  {LL.insights.sidebar.facebook.quotaAlert({
                    quota,
                  })}{' '}
                  <Button className="underline underline-offset-4 focus:outline-none">
                    {LL.upgrade.upgradeNow()}
                  </Button>
                </span>
              </MenuItem>
            ) : (
              <MenuItem className="focus:outline-none">
                <AriaButton
                  className="w-full"
                  variant="secondary"
                  onPress={authenticate}
                  loading={isLoading}
                  icon={
                    <Icon>
                      <Facebook />
                    </Icon>
                  }
                >
                  <span className="text-xs font-semibold">
                    {LL.insights.sidebar.facebook.cta()}
                  </span>
                </AriaButton>
              </MenuItem>
            )}
          </Menu>
        </Popover>
      </MenuTrigger>

      {user.data && (
        <FacebookAdAccountSelectModal
          pendingAuthState={
            pendingAuthState
              ? {
                  ...pendingAuthState,
                  currentIntegrations: user.data.facebookIntegrations,
                }
              : null
          }
          onOpenChange={(isOpen) => {
            if (!isOpen) {
              setPendingAuthState(null);
              void trpcUtils.user.getUserAndOrganisation.invalidate();
            }
          }}
        />
      )}
    </>
  );
};
