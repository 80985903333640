import { useState } from 'react';
import { captureException } from '@sentry/react';
import { toast } from 'react-toastify';
import { useI18nContext } from 'src/i18n/i18n-react';
import { AdAccount, getAllAdAccounts, login } from 'src/lib/facebook';
import { useUserAndOrganisation } from './useUserAndOrganisation';

export type PendingAuthState = {
  currentIntegrations: string[];
  adAccounts: AdAccount[];
  fbUserID: string;
  accessToken: string;
};

export const useFacebook = () => {
  const { LL } = useI18nContext();
  const user = useUserAndOrganisation();

  const [pendingAuthState, setPendingAuthState] =
    useState<PendingAuthState | null>(null);

  const authenticate = async () => {
    try {
      const { fbUserID, accessToken } = await login();

      const adAccounts = await getAllAdAccounts();

      setPendingAuthState({
        fbUserID,
        accessToken,
        adAccounts,
        currentIntegrations: user.data?.facebookIntegrations ?? [],
      });
    } catch (e) {
      captureException(e);
      toast.error(
        LL.integrations.facebook.authenticateFacebook.error({
          error:
            e instanceof Error && 'message' in e ? e.message : 'Unknown error',
        }),
        { className: 'toast-danger' }
      );
    }
  };

  return {
    authenticate,
    pendingAuthState,
    setPendingAuthState,
  };
};
