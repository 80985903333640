import { createContext, useContext } from 'react';
import {
  InsightsGroup,
  type ActiveMetric,
  type CustomMetric,
  type Metric,
} from '@magicbrief/common';
import { atom, Atom, useSetAtom, WritableAtom, useAtomValue } from 'jotai';
import { isEqual } from 'lodash';
import { atomWithStorage, createJSONStorage } from 'jotai/utils';
import { SyncStorage } from 'jotai/vanilla/utils/atomWithStorage';
import { inferProcedureOutput } from '@trpc/server';
import { AppRouter } from '@magicbrief/server/src/trpc/router';
export type InsightsSort = {
  id: Metric | CustomMetric;
  desc: boolean;
};

export interface InsightsState {
  display: Array<Metric | CustomMetric>;
  sort: Array<InsightsSort>;
  filter: Array<ActiveMetric>;
  group: InsightsGroup | null;
  columnSizing: Record<string, number>;
}

export type InsightsView = 'overview' | 'analysis';

type InsightsStateAction =
  | {
      type: 'setDisplay';
      value: Array<Metric | CustomMetric>;
    }
  | {
      type: 'setColumnSizing';
      value: Record<string, number>;
    }
  | { type: 'toggleDisplay'; value: Metric | CustomMetric }
  | {
      type: 'setFilter';
      value: Array<ActiveMetric>;
    }
  | { type: 'addFilter'; value: ActiveMetric }
  | { type: 'removeFilter'; value: ActiveMetric }
  | {
      type: 'setSort';
      value: Array<InsightsSort>;
    }
  | { type: 'toggleSort'; value: InsightsSort }
  | {
      type: 'setGroup';
      value: InsightsGroup | null;
    };

const DEFAULT_INSIGHTS_FILTER: Array<ActiveMetric> = [];
const DEFAULT_INSIGHTS_SORT: Array<InsightsSort> = [
  { id: 'spend', desc: true },
  { id: 'clicks', desc: true },
  { id: 'impressions', desc: true },
];
const DEFAULT_INSIGHTS_ANALYSIS_SORT: Array<InsightsSort> = [
  { id: 'spend', desc: true },
];
const DEFAULT_INSIGHTS_DISPLAY: Array<Metric | CustomMetric> = [
  'spend',
  'roas',
  'link_click',
  'cpm',
  'cpc',
  'wizardHookScore',
  'wizardHoldScore',
  'wizardClickScore',
];

type InsightsPersistentStateContextValue = {
  all: WritableAtom<InsightsState, [InsightsStateAction], void>;
  filter: Atom<Array<ActiveMetric>>;
  display: Atom<Array<Metric | CustomMetric>>;
  columnSizing: Atom<Record<string, number>>;
  sort: Atom<Array<InsightsSort>>;
  group: Atom<InsightsGroup | null>;
};

export const InsightsPersistentStateContext =
  createContext<InsightsPersistentStateContextValue | null>(null);

function reducer(
  state: InsightsState,
  { value, type }: InsightsStateAction
): InsightsState {
  switch (type) {
    case 'setDisplay':
      return {
        ...state,
        display: value,
      };
    case 'setSort':
      return {
        ...state,
        sort: value,
      };
    case 'setFilter':
      return {
        ...state,
        filter: value,
      };
    case 'setGroup':
      return {
        ...state,
        group: value,
      };
    case 'toggleDisplay': {
      const oldValue = state.display;
      const idx = oldValue?.findIndex((x) => x === value);
      return {
        ...state,
        display:
          idx === -1
            ? [...oldValue, value]
            : [...oldValue.slice(0, idx), ...oldValue.slice(idx + 1)],
      };
    }
    case 'addFilter': {
      const currentFilters = state.filter;

      // Find the index of the filter that deeply equals the incoming filter
      const matchingDeeplyEqualIndex = currentFilters.findIndex((filter) =>
        isEqual(filter, value)
      );

      // Do not mutate any filter state if the user adds the exact same filter
      if (matchingDeeplyEqualIndex !== -1) {
        return state;
      }

      return {
        ...state,
        filter: [...currentFilters, value],
      };
    }
    case 'removeFilter': {
      const currentFilters = state.filter;

      const newFilters = currentFilters.filter(
        (filter) => !isEqual(filter, value)
      );

      return {
        ...state,
        filter: newFilters,
      };
    }
    case 'toggleSort': {
      const oldValue = state.sort;
      const idx = oldValue?.findIndex((x) => x.id === value.id);
      return {
        ...state,
        sort:
          idx === -1
            ? [...oldValue, value]
            : [...oldValue.slice(0, idx), ...oldValue.slice(idx + 1)],
      };
    }
    case 'setColumnSizing': {
      return {
        ...state,
        columnSizing: value,
      };
    }
  }
}

export function createInsightsAtom({
  accountUuid,
  reportUuid,
  view,
  type,
  initialState,
}: {
  type: 'standard' | 'report';
  accountUuid?: string;
  reportUuid?: string;
  view: InsightsView | null;
  initialState:
    | inferProcedureOutput<
        AppRouter['insightsReports']['getInsightsReport']
      >['config']
    | null;
}): WritableAtom<InsightsState, [InsightsStateAction], void> {
  const createReportStorage = (
    mergeValue: InsightsState
  ): SyncStorage<InsightsState> => {
    const storage = createJSONStorage<InsightsState>(() => localStorage);
    const getItem = (key: string, initialValue: InsightsState) => {
      const value = storage.getItem(
        key,
        initialValue
      ) as Partial<InsightsState>;
      return { ...mergeValue, ...value };
    };
    const setItem = (key: string, value: InsightsState) => {
      const { columnSizing, display } = value;
      return storage.setItem(key, { columnSizing, display } as InsightsState);
    };
    return { ...storage, getItem, setItem };
  };

  const reportInitialState: InsightsState = {
    filter: initialState?.filter.metric ?? [],
    display: initialState?.filter.display ?? [],
    sort: initialState?.filter?.sort.map((x) => ({ id: x, desc: true })) ?? [],
    group: initialState?.group ?? null,
    columnSizing: {},
  };

  const storageAtom =
    type === 'report'
      ? atomWithStorage<InsightsState>(
          `insights_report_${reportUuid ?? ''}`,
          reportInitialState,
          createReportStorage(reportInitialState),
          { getOnInit: true }
        )
      : atomWithStorage<InsightsState>(
          `insights_account_${accountUuid ?? ''}_${view}`,
          {
            filter: DEFAULT_INSIGHTS_FILTER,
            display: DEFAULT_INSIGHTS_DISPLAY,
            sort:
              view === 'overview'
                ? DEFAULT_INSIGHTS_SORT
                : DEFAULT_INSIGHTS_ANALYSIS_SORT,
            group: null,
            columnSizing: {},
          },
          undefined,
          { getOnInit: true }
        );

  const storageAtomWithReducer = atom<
    InsightsState,
    [InsightsStateAction],
    void
  >(
    (get) => get(storageAtom),
    (get, set, action) => {
      set(storageAtom, reducer(get(storageAtom), action));
    }
  );

  return storageAtomWithReducer;
}

export function useInsightsFilter() {
  const ctx = useContext(InsightsPersistentStateContext);
  if (!ctx) {
    throw new Error(
      'InsightsPersistentStateContext accessed outside InsightsPersistentStateContextProvider'
    );
  }
  const value = useAtomValue(ctx.filter);
  return value;
}

export function useInsightsSort() {
  const ctx = useContext(InsightsPersistentStateContext);
  if (!ctx) {
    throw new Error(
      'InsightsPersistentStateContext accessed outside InsightsPersistentStateContextProvider'
    );
  }
  const value = useAtomValue(ctx.sort);
  return value;
}

export function useInsightsDisplay() {
  const ctx = useContext(InsightsPersistentStateContext);
  if (!ctx) {
    throw new Error(
      'InsightsPersistentStateContext accessed outside InsightsPersistentStateContextProvider'
    );
  }
  const value = useAtomValue(ctx.display);
  return value;
}

export function useInsightsGroup() {
  const ctx = useContext(InsightsPersistentStateContext);
  if (!ctx) {
    throw new Error(
      'InsightsPersistentStateContext accessed outside InsightsPersistentStateContextProvider'
    );
  }
  const value = useAtomValue(ctx.group);
  return value;
}

export function useInsightsColumnSize() {
  const ctx = useContext(InsightsPersistentStateContext);
  if (!ctx) {
    throw new Error(
      'InsightsPersistentStateContext accessed outside InsightsPersistentStateContextProvider'
    );
  }
  const value = useAtomValue(ctx.columnSizing);
  return value;
}

export function useInsightsStoreDispatch() {
  const ctx = useContext(InsightsPersistentStateContext);
  if (!ctx) {
    throw new Error(
      'InsightsPersistentStateContext accessed outside InsightsPersistentStateContextProvider'
    );
  }
  const dispatch = useSetAtom(ctx.all);
  return dispatch;
}
