import { useCallback } from 'react';
import { FacebookDate, SelectableDatePreset } from '@magicbrief/common';
import { ForTimePeriodInput } from '@magicbrief/server/src/insights/types';

import { useTypesafeSearchParams } from 'src/utils/useTypesafeSearchParams';

export type MetricString =
  /** @example text:      'adName|isSet' */
  | `${string}|${string}`
  /** @example text:      'adName|is|checkout%20new%20feature' */
  /** @example numerical: 'spend|equals|2.0' */
  /** @example multi:     'creativeType|in|image_video_carousel' */
  | `${string}|${string}|${string}`
  /** @example numerical: 'spend|between|2.0|3.0' */
  | `${string}|${string}|${string}|${string}`;

type InsightsSearchParams = {
  timePeriod: SelectableDatePreset | 'custom';
  since: FacebookDate | null;
  until: FacebookDate | null;
  attributionWindow: 'default' | 'custom';
  view: 'table' | 'grid';
};

type InsightsSearchParamsKey = keyof InsightsSearchParams;

export function useInsightsSearchParams() {
  const search = useTypesafeSearchParams<InsightsSearchParams>({
    timePeriod: 'last_14d',
    since: null,
    until: null,
    attributionWindow: 'default',
    view: 'table',
  });

  const getRawValues = () => ({
    timePeriod: search.get('timePeriod'),
    attributionWindow: search.get('attributionWindow'),
    since: search.get('since'),
    until: search.get('until'),
  });

  const getParsedValues = () => {
    const timePeriod = search.get('timePeriod') ?? 'last_14d';
    const since = search.get('since');
    const until = search.get('until');
    const attributionWindow = search.get('attributionWindow');

    const forTimePeriod: ForTimePeriodInput =
      timePeriod === 'custom'
        ? {
            datePreset: 'custom',
            since: since ?? '',
            until: until ?? '',
          }
        : {
            datePreset: timePeriod,
          };

    return {
      timePeriod,
      since,
      until,
      attributionWindow,
      forTimePeriod,
    };
  };

  const setTimePeriodValue = (value: InsightsSearchParams['timePeriod']) => {
    search.set('timePeriod', value);
  };

  const setSinceValue = (value: InsightsSearchParams['since']) =>
    search.set('since', value);

  const setUntilValue = (value: InsightsSearchParams['until']) =>
    search.set('until', value);

  const setManyValues = useCallback(
    (values: {
      timePeriod: SelectableDatePreset | 'custom';
      since?: FacebookDate;
      until?: FacebookDate;
      attributionWindow?: 'default' | 'custom';
      view?: 'table' | 'grid';
    }) => {
      return search.setMany({
        timePeriod: values.timePeriod,
        since: values.since,
        until: values.until,
        attributionWindow: values.attributionWindow,
        view: values.view,
      });
    },
    [search]
  );

  const deleteValues = (key: InsightsSearchParamsKey) =>
    search.deleteByKey(key);

  return {
    getRawValues,
    getParsedValues,
    setTimePeriodValue,
    setSinceValue,
    setUntilValue,
    setManyValues,
    deleteValues,
    params: search,
  };
}
