import { useContext, useState } from 'react';
import {
  Button,
  Dialog,
  Heading,
  Modal,
  ModalOverlay,
} from 'react-aria-components';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { inferProcedureOutput } from '@trpc/server';
import { AppRouter } from '@magicbrief/server/src/trpc/router';
import { ArrayElement } from '@magicbrief/common';
import Bell02 from 'src/assets/svgicons/duotone/bell-02.svg';
import Building07 from 'src/assets/svgicons/duotone/building-07.svg';
import Check from 'src/assets/svgicons/line/check.svg';
import Eye from 'src/assets/svgicons/duotone/eye.svg';
import LayerSingle from 'src/assets/svgicons/duotone/layer-single.svg';
import LayersTwo02 from 'src/assets/svgicons/duotone/layers-two-02.svg';
import LayersThree02 from 'src/assets/svgicons/duotone/layers-three-02.svg';
import LightBulb02 from 'src/assets/svgicons/duotone/lightbulb-02.svg';
import UserSquare from 'src/assets/svgicons/duotone/user-square.svg';
import XClose from 'src/assets/svgicons/line/x-close.svg';
import { MagicBriefButton } from 'src/components/Button/MagicBriefButton';
import { Icon } from 'src/components/Icon';
import { cn } from 'src/lib/cn';
import { trpc } from 'src/lib/trpc';
import { useUserAndOrganisation } from 'src/utils/useUserAndOrganisation';
import { useI18nContext } from 'src/i18n/i18n-react';
import {
  UpgradeModalV2Context,
  UpgradeModalV2Provider,
} from './UpgradeModalV2.context';
import type { EventTarget } from '@magicbrief/server/src/services/analytics/analytics';

interface UpgradeModalV2Props {
  /* Open state of the modal */
  isOpen: boolean;

  /** Callback to close the modal */
  close: () => void;

  /**  Custom prompt used in the Paywall header */
  prompt?: string;

  /** Context of where the Paywall was triggered from for analytics event */
  target?: EventTarget;
}

export const UpgradeModalV2 = ({
  isOpen,
  close,
  prompt,
  target,
}: UpgradeModalV2Props) => {
  return (
    <UpgradeModalV2Provider
      isOpen={isOpen}
      close={close}
      prompt={prompt}
      target={target}
    >
      <UpgradeModalV2Content />
    </UpgradeModalV2Provider>
  );
};

const UpgradeModalV2Content = () => {
  const { isOpen, close } = useContext(UpgradeModalV2Context);

  return (
    <ModalOverlay
      isOpen={isOpen}
      onOpenChange={close}
      isDismissable={true}
      className={cn([
        /* Base */
        'fixed inset-0 z-[9999] bg-gray-900/80',
        /* Entering */
        'data-[entering]:animate-[overlayShow_300ms_ease-in-out]',
        /* Exiting */
        'data-[exiting]:animate-[overlayHide_300ms_ease-in-out]',
      ])}
    >
      <Modal
        className={cn([
          /* Base */
          'fixed left-[50%] top-[50%] z-[9999] w-11/12 translate-x-[-50%] translate-y-[-50%] sm:w-[600px] lg:min-w-[1000px]',
          /* Entering */
          'data-[entering]:duration-300 data-[entering]:animate-in data-[entering]:fade-in-0 data-[entering]:zoom-in-95 data-[entering]:slide-in-from-left-1/2 data-[entering]:slide-in-from-top-[48%]',
          /* Exiting */
          'data-[exiting]:duration-300 data-[exiting]:animate-out data-[exiting]:fade-out-0 data-[exiting]:zoom-out-95 data-[exiting]:slide-out-to-left-1/2 data-[exiting]:slide-out-to-top-[48%]',
        ])}
      >
        <Dialog
          className="relative grid h-[800px] gap-4 overflow-y-auto rounded-2xl border border-purple-200 bg-white px-8 py-6 shadow focus-visible:outline-none lg:h-auto"
          aria-label="billing plans modal"
        >
          <UpgradePlanHeader />
          <StripePlans />
          <MoreInformationLink />
        </Dialog>
      </Modal>
    </ModalOverlay>
  );
};

const UpgradePlanHeader = () => {
  const user = useUserAndOrganisation();
  const { close, prompt, billingFrequency, setBillingFrequency } = useContext(
    UpgradeModalV2Context
  );

  const isFreeUser = user.data?.organisation.billingState === 'free';
  const header = isFreeUser ? 'Start your free trial' : 'Upgrade';

  return (
    <>
      <div className="relative flex flex-col justify-between gap-2 pr-8 lg:flex-row lg:pr-12">
        <Heading className="text-2xl font-semibold text-primary">
          {header} <span className="text-purple-500">{prompt}</span>
        </Heading>

        <div className="flex w-fit rounded-lg border border-solid border-purple-200 bg-purple-100 shadow-[0_1px_3px_0_rgba(41,14,131,0.05),0_1px_2px_0_rgba(41,14,131,0.06)]">
          <Button
            className={cn(
              'rounded-lg px-4 py-2.5 text-sm text-primary',
              'focus-visible:outline-none focus-visible:ring focus-visible:ring-secondary',
              'focus:outline-none',
              billingFrequency === 'monthly' ? 'bg-white' : ''
            )}
            onPress={() => setBillingFrequency('monthly')}
          >
            Monthly
          </Button>
          <Button
            className={cn(
              'rounded-lg px-4 py-2.5 text-sm text-primary',
              'focus-visible:outline-none focus-visible:ring focus-visible:ring-secondary',
              'focus:outline-none',
              billingFrequency === 'yearly' ? 'bg-white' : ''
            )}
            onPress={() => setBillingFrequency('yearly')}
          >
            Annually
          </Button>
        </div>
      </div>

      {/* Close Button */}
      <Button
        className="absolute right-7 top-7 rounded-full p-1 transition-colors duration-300 hover:bg-purple-50 focus:outline-none focus-visible:outline-none focus-visible:ring focus-visible:ring-secondary"
        onPress={close}
      >
        <Icon className="size-6 text-[#3D1CAF80]">
          <XClose />
        </Icon>
      </Button>
    </>
  );
};

const StripePlans = () => {
  const { isOpen } = useContext(UpgradeModalV2Context);

  const plans = trpc.stripe.getNewPlansForOrg.useQuery(undefined, {
    enabled: isOpen,
  });

  if (plans.isLoading) {
    return (
      <div className="grid grid-cols-1 gap-4 lg:grid-cols-3">
        <div
          className="h-[625px] w-[302px] animate-pulse rounded-xl bg-gray-100"
          aria-label="loading"
        />
        <div
          className="h-[625px] w-[302px] animate-pulse rounded-xl bg-gray-100"
          aria-label="loading"
        />
        <div
          className="h-[625px] w-[302px] animate-pulse rounded-xl bg-gray-100"
          aria-label="loading"
        />
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 gap-4 lg:grid-cols-3">
      {plans.data
        ?.sort((a, b) => a.paywallOrder - b.paywallOrder)
        ?.map((plan) => <PlanCard key={plan.productName} plan={plan} />)}
    </div>
  );
};

const formatCurrency = (priceInCents: number) => {
  const price = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  }).format(priceInCents / 100);

  return price;
};

const workspaceIconMap = {
  seat: <UserSquare />,
  brand: <Building07 />,
  'ad-account': <Eye />,
  notify: <Bell02 />,
  'creative-intelligence': <LightBulb02 />,
};

interface PlanCardProps {
  plan: ArrayElement<
    inferProcedureOutput<AppRouter['stripe']['getNewPlansForOrg']>
  >;
}

const PlanCard = ({ plan }: PlanCardProps) => {
  const { billingFrequency } = useContext(UpgradeModalV2Context);

  const productInfo = trpc.stripe.getPlanPrices.useQuery(
    { productID: plan.stripeProductID ?? '' },
    { enabled: !!plan.stripeProductID }
  );

  const isLitePlan = plan.productName === 'Lite';
  const isCreativeIntelligencePlan =
    plan.productName === 'Creative Intelligence';
  const isTalkToSales = plan.talkToSales;
  const price =
    billingFrequency === 'monthly'
      ? formatCurrency(productInfo.data?.monthlyPerMonthCentPrice ?? 0)
      : formatCurrency(productInfo.data?.yearlyPerMonthCentPrice ?? 0);
  const priceDescription =
    billingFrequency === 'monthly'
      ? 'per month, billed monthly'
      : 'per month, billed annually';

  return (
    <div
      className={cn(
        'space-y-6 rounded-xl border border-solid border-purple-200 p-6',
        isLitePlan && 'border-purple-400 bg-purple-50'
      )}
    >
      <div className="flex flex-col gap-3 rounded-xl">
        <div className="flex justify-between">
          <PlanIcon productName={plan.productName} />
          {isLitePlan && (
            <div className="h-fit rounded-xl bg-purple-500 px-2.5 py-1 text-xs font-medium text-white">
              Recommended
            </div>
          )}
        </div>

        <span className="text-2xl font-semibold text-primary">
          {plan.productName}
        </span>

        <div className="flex items-baseline gap-1">
          <span className="text-3xl font-bold text-primary">
            {isTalkToSales ? 'Custom' : price}
          </span>
          {!isTalkToSales && (
            <span className="text-xs font-medium text-primary">
              {priceDescription}
            </span>
          )}
        </div>
      </div>

      <div>
        <span className="text-sm font-semibold text-primary">
          {plan.productTagline}
        </span>
      </div>

      <PlanCTA plan={plan} />

      {/* Plan Info */}
      <div className="flex min-h-[180px] flex-col gap-3">
        {isCreativeIntelligencePlan ? (
          <span className="text-sm font-semibold text-primary">
            Launching Soon
          </span>
        ) : (
          <span className="text-sm font-semibold text-primary">
            {plan.featureHeader}
          </span>
        )}

        {isCreativeIntelligencePlan ? (
          <span className="text-sm font-medium text-primary">
            Currently onboarding design partners to our flagship creative
            intelligence suite - limited spots remaining.
          </span>
        ) : (
          <ul className="flex flex-col gap-3">
            {plan.featureList.map((feature) => (
              <BillingPlanHighlight key={feature} label={feature} />
            ))}
          </ul>
        )}
      </div>

      <div className="flex flex-col gap-3">
        <span className="text-sm font-semibold text-primary">Workspace</span>

        <ul className="flex flex-col gap-3">
          {plan.workspaceMetadata.items.map((item) =>
            item.icon === 'creative-intelligence' ? (
              <li key={item.icon} className="flex gap-3">
                <Icon className="size-5 text-primary">
                  {workspaceIconMap[item.icon as keyof typeof workspaceIconMap]}
                </Icon>
                <div className="flex items-center gap-2">
                  <span className="text-sm font-medium text-primary">
                    {item.descriptor}
                  </span>
                  <span className="rounded-md bg-primary px-2 py-0.5 text-xs font-semibold text-white">
                    BETA
                  </span>
                </div>
              </li>
            ) : (
              <li key={item.icon} className="flex gap-3">
                <Icon className="size-5 text-primary">
                  {workspaceIconMap[item.icon as keyof typeof workspaceIconMap]}
                </Icon>
                <span className="text-sm font-medium text-primary">
                  {item.descriptor}
                </span>
              </li>
            )
          )}
        </ul>
      </div>
    </div>
  );
};

const PlanIcon = ({ productName }: { productName: string }) => {
  const getPlanIcon = () => {
    switch (productName) {
      case 'Lite':
        return <LayerSingle />;
      case 'Team':
        return <LayersTwo02 />;
      case 'Creative Intelligence':
        return <LayersThree02 />;
      default:
        return null;
    }
  };

  return (
    <Icon className="size-10 rounded-md bg-purple-200 p-2 text-primary">
      {getPlanIcon()}
    </Icon>
  );
};

const PlanCTA = ({ plan }: PlanCardProps) => {
  const user = useUserAndOrganisation();

  const { client } = trpc.useContext();
  const { billingFrequency } = useContext(UpgradeModalV2Context);

  const [isUpgradeCheckLoading, setIsUpgradeCheckLoading] = useState(false);
  const [isConfirmUpgradeOpen, setIsConfirmUpgradeOpen] = useState(false);

  const organisation = trpc.organisation.getOrganisation.useQuery();
  const createCheckoutUrl = trpc.stripe.getNewPortalCheckoutLink.useMutation();

  const isFreeUser = user.data?.organisation.billingState === 'free';
  const isLoading = createCheckoutUrl.isLoading || isUpgradeCheckLoading;
  const isLitePlan = plan.productName === 'Lite';
  const isTalkToSales = plan.talkToSales;
  const isCurrentPlan =
    plan.stripeProductID === organisation.data?.subscribedStripeProduct;
  const isPlanEnabled =
    (plan.enabled &&
      plan.supportedFrequencies.includes(billingFrequency) &&
      !isCurrentPlan) ||
    isTalkToSales;

  const getLabel = () => {
    switch (true) {
      case isCurrentPlan:
        return 'Current Plan';
      case isTalkToSales:
        return 'Request a demo';
      case !isPlanEnabled:
        return 'Unavailable';
      case isLitePlan && isFreeUser:
        return 'Start 14-day free trial';
      case isLitePlan:
        return 'Upgrade';
      default:
        return '';
    }
  };

  const handlePress = async () => {
    if (isTalkToSales) {
      const win = window.open('https://magicbrief.com/tour', '_blank');
      if (win != null) {
        win.focus();
        return;
      }
    }

    setIsUpgradeCheckLoading(true);

    const canUpgradeDirectly =
      await client.stripe.canUpgradeOrgDirectly.query();

    setIsUpgradeCheckLoading(false);

    if (canUpgradeDirectly) {
      setIsConfirmUpgradeOpen(true);
    } else {
      if (plan.stripeProductID) {
        createCheckoutUrl.mutate(
          {
            productID: plan.stripeProductID,
            billingFrequency,
          },
          {
            onSuccess: (response) => {
              window.location.href = response;
            },
          }
        );
      }
    }
  };

  return (
    <>
      <MagicBriefButton
        variant={isLitePlan ? 'cta' : 'secondary'}
        size="small"
        onPress={handlePress}
        loading={isLoading}
        isDisabled={!isPlanEnabled || isLoading}
      >
        {getLabel()}
      </MagicBriefButton>
      <ConfirmUpgradeModal
        isOpen={isConfirmUpgradeOpen}
        close={() => setIsConfirmUpgradeOpen(false)}
        plan={plan}
      />
    </>
  );
};

function BillingPlanHighlight({ label }: { label: string }) {
  return (
    <li className="flex items-center gap-3">
      <Icon className="size-3 text-primary">
        <Check />
      </Icon>
      <span className="text-sm font-medium text-primary">{label}</span>
    </li>
  );
}

function MoreInformationLink() {
  return (
    <div className="flex justify-center">
      <Link
        className="rounded-sm p-1 text-sm font-semibold text-primary underline focus-visible:outline-none focus-visible:ring focus-visible:ring-secondary"
        to="https://magicbrief.com/pricing"
        target="_blank"
        rel="noreferrer noopener"
      >
        See all features
      </Link>
    </div>
  );
}

interface ConfirmUpgradeModalProps {
  isOpen: boolean;
  close: () => void;
  plan: ArrayElement<
    inferProcedureOutput<AppRouter['stripe']['getNewPlansForOrg']>
  >;
}

function ConfirmUpgradeModal({
  isOpen,
  close,
  plan,
}: ConfirmUpgradeModalProps) {
  const { LL } = useI18nContext();
  const { billingFrequency } = useContext(UpgradeModalV2Context);
  const user = useUserAndOrganisation();
  const trpcUtils = trpc.useUtils();

  const updatePlan =
    trpc.stripe.updateOrgToNewPricePointAndFrequency.useMutation();

  const handleUpdatePlan = () => {
    updatePlan.mutate(
      {
        productID: plan.stripeProductID ?? '',
        billingFrequency,
      },
      {
        onSuccess: async () => {
          toast.success(LL.upgrade.upgradeSuccess());
          await user.refetch();
          void trpcUtils.stripe.getNewPlansForOrg.invalidate();
          void trpcUtils.adBrands.getAllowedBrands.invalidate();

          setTimeout(() => {
            window.location.reload();
            /** @todo this is a bit of a hack to ensure the customer upgrade is finished
             * it could probably be sured up and decreased (keep some indication for UX before reload)
             */
          }, 5000);
        },
      }
    );
  };

  return (
    <ModalOverlay
      isOpen={isOpen}
      onOpenChange={close}
      isDismissable={true}
      className="fixed inset-0 z-[9999] bg-gray-900/20"
    >
      <Modal
        className={cn([
          /* Base */
          'fixed left-[50%] top-[50%] z-[9999] max-w-md translate-x-[-50%] translate-y-[-50%]',
          /* Entering */
          'data-[entering]:duration-300 data-[entering]:animate-in data-[entering]:fade-in-0 data-[entering]:zoom-in-95 data-[entering]:slide-in-from-left-1/2 data-[entering]:slide-in-from-top-[48%]',
          /* Exiting */
          'data-[exiting]:duration-300 data-[exiting]:animate-out data-[exiting]:fade-out-0 data-[exiting]:zoom-out-95 data-[exiting]:slide-out-to-left-1/2 data-[exiting]:slide-out-to-top-[48%]',
        ])}
      >
        <Dialog
          className="relative flex h-[800px] flex-col gap-6 rounded-2xl border border-purple-200 bg-white px-8 py-6 shadow lg:h-auto"
          aria-label="confirm upgrade modal"
        >
          <Heading className="text-xl font-semibold text-primary">
            Upgrade your plan
          </Heading>
          <Button
            className="absolute right-6 top-5 rounded-full p-1 transition-colors duration-300 hover:bg-purple-50"
            onPress={close}
          >
            <Icon className="size-6 text-[#3D1CAF80]">
              <XClose />
            </Icon>
          </Button>

          <span className="text-md font-medium text-primary">
            {`Would you like to upgrade to the ${plan.productName} plan?`}
          </span>

          <div className="flex items-center justify-end gap-2">
            <MagicBriefButton variant="secondary" size="medium" onPress={close}>
              Cancel
            </MagicBriefButton>
            <MagicBriefButton
              variant="primary"
              size="medium"
              onPress={handleUpdatePlan}
            >
              {updatePlan.isLoading ? 'Loading...' : 'Confirm'}
            </MagicBriefButton>
          </div>
        </Dialog>
      </Modal>
    </ModalOverlay>
  );
}
